<template>
  <div>
    <!-- 头部 -->
    <new-header :config="headerConfig"></new-header>
    <!-- 表單 -->
    <div class="c_content">
      <div class="c_record" @click="lookBtn">变更记录 ></div>
      <div class="c_from">
        <div class="c_title">信息变更操作说明</div>
        <div style="width:100%" v-html="configData.shares_change_about"></div>
        <div class="c_submit" @click="submitBtn">申请物料变更</div>
      </div>

    </div>
  </div>
</template>
<script>
import newHeader from "@/components/newHeader";
import { getConfig_api } from "@/api/deal";
export default {
  components: {
    newHeader
  },
  data() {
    return {
      headerConfig: {
        show: true,
        title: "物料变更",
        showKeufu: true,
      },
      configData: {}
    };
  },
  created() {
    console.log(123);
    this.getSeting()
  },
  methods: {
    getSeting() {
      let data = {
        config: ["shares_change_about"]
      };
      getConfig_api(data).then(res => {
        this.configData = res.data;
      });
    },
    submitBtn() {
      this.$router.push("./selectAlteration")
    },
    lookBtn() {
      this.$router.push("./applicationRecord")
    }
  }
};
</script>
<style scoped>
.c_content {
  width: 100%;
  min-height: calc(100vh - 92px);
  background-image: url(../../assets/Slice363@2x.png);
  background-size: 100% 100%;
  padding: 30px;
  box-sizing: border-box;
}

.c_from {
  width: 690px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 20px auto;
  padding-bottom: 30px
}

.c_from p {
  width: 700px
}

.c_record {
  font-size: 26px;
  color: #fff;
}

.c_from {
  padding: 42px 35px;
  box-sizing: border-box;
  font-size: 28px;
  color: #fff;
}

.c_from div {
  line-height: 40px;
}

.c_title {
  text-align: center;
  font-size: 34px;
  margin-bottom: 40px;
}

.c_from .c_submit {
  width: 300px;
  height: 80px;
  line-height: 80px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid #ffffff;
  text-align: center;
  ;
  margin: 86px auto 0;
  color: #fff;
  font-size: 30px
}
</style>
